import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const dummy = [
  {
    id: 1,
    name: "Jane Doe",
  },
  {
    id: 2,
    name: "Jane Doe",
  },
  {
    id: 3,
    name: "Jane Doe",
  },
  {
    id: 4,
    name: "Jane Doe",
  },
  {
    id: 5,
    name: "Jane Doe",
  },
  {
    id: 6,
    name: "Jane Doe",
  },
  {
    id: 7,
    name: "Jane Doe",
  },
  {
    id: 8,
    name: "Jane Doe",
  },
  {
    id: 9,
    name: "Jane Doe",
  },
];

const Employee = () => {
  return (
    <Flex w="100%">
      <Box
        h="100vh"
        w="20rem"
        bg="blackAlpha.300"
        py={5}
        px={8}
        overflowY="auto"
      >
        <Heading fontSize="1.5rem" fontWeight="600">
          Employees
        </Heading>
        <HStack my={3}>
          <Text>Total employees</Text>
          <Text fontSize="1.5rem" fontWeight="600">
            56
          </Text>
        </HStack>
        <Flex bg="whiteAlpha.800" py={2} px={5} borderRadius={5}>
          <Text w="2rem">ID</Text>
          <Text>Candidate Name</Text>
        </Flex>
        <VStack w="100%" mt={6}>
          {dummy.map((_, index) => {
            return <EmployeeCard props={_} key={index} />;
          })}
        </VStack>
      </Box>
      <Outlet />
    </Flex>
  );
};

const EmployeeCard = ({ props }: { props: { id: number; name: string } }) => {
  const { id, name } = props;
  const navigate = useNavigate();

  return (
    <Flex
      bg="whiteAlpha.800"
      py={2}
      px={5}
      borderRadius={5}
      w="100%"
      onClick={() => navigate("/admin/employee/0001")}
    >
      <Text w="2rem">{id}</Text>
      <Text>{name}</Text>
    </Flex>
  );
};

export default Employee;
