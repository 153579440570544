import { Box, Button, Flex, Spacer, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ActionCard = ({
  icon,
  link,
  title,
}: {
  icon: JSX.Element;
  link: string;
  title: string;
}) => {
  const navigate = useNavigate();

  return (
    <Button
    cursor="pointer"
      onClick={() => navigate(link)}
      w="100%"
      colorScheme="none"
      as={Flex}
      alignItems="left"
      flexDir="column"
      color="white"
      bg="blue.300"
      h="100%"
      p={4}
      _hover={{
        bg: "cyan 1s.300",
      }}
      transition="all ease-in"
    >
      <Box bg="whiteAlpha.400" w="fit-content" p={2}>
        {icon}
      </Box>
      <Spacer />
      <Heading mb={3} fontSize="1.5rem" fontWeight="500">
        {title}
      </Heading>
    </Button>
  );
};

export default ActionCard;
