import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Details = () => {
  return (
    <Box borderWidth="1px" borderColor="blackAlpha.300" borderRadius={5}>
      <Heading
        borderBottomWidth="1px"
        borderColor="blackAlpha.300"
        color="blackAlpha.700"
        px={4}
        py={3}
        fontSize="1.2rem"
        fontWeight="500"
      >
        Detail
      </Heading>
      <Flex flexWrap="wrap" rowGap={5} alignItems="left" py={5} px={4}>
        <Box w="50%">
          <Heading fontSize="1.2rem" fontWeight="500">
            Current Status
          </Heading>
          <Text
            bg="whatsapp.500"
            color="white"
            px={2}
            borderRadius={5}
            fontWeight="600"
            w="fit-content"
          >
            Active
          </Text>
        </Box>
        <DetailCard props={{ title: "ID No", info: "0000004" }} />
        <DetailCard props={{ title: "Department", info: "Finance" }} />
        <DetailCard props={{ title: "Office", info: "UX designer" }} />
        <DetailCard props={{ title: "Mode Of Work", info: "Remote" }} />
        <DetailCard props={{ title: "Hired By", info: "John Doe" }} />
        <DetailCard
          props={{ title: "Date joined", info: "14th March, 2021" }}
        />
      </Flex>
    </Box>
  );
};

const DetailCard = ({ props }: { props: { title: string; info: string } }) => {
  const { title, info } = props;

  return (
    <Box w="50%">
      <Heading fontSize="1.2rem" fontWeight="500">
        {title}
      </Heading>
      <Text>{info}</Text>
    </Box>
  );
};

export default Details;
