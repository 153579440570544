import React from "react";
import "../css/loader.css";
import { Box, Flex } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex
      pos="fixed"
      h="100vh"
      w="100%"
      bg="blue.500"
      justifyContent="center"
      alignItems="center"
      zIndex={100}
    >
      <div className="loader" />
    </Flex>
  );
};

export default Loader;
