import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import baseURL from "../../utils/baseURL";
import axios from "axios";

const PortfolioModal = () => {
  const navigate = useNavigate();
  const { onClose } = useDisclosure();

  const [project, setProject] = useState<{
    title: string;
    description: string;
    status: string;
    link: string;
    cover_photo: string;
    stack: string[];
    tags: string[];
  }>();

  useEffect(() => {
    const handleFetch = async () => {
      const id = window.location.pathname.split("/")[2];

      const { data } = await axios.get(`${baseURL}/project/${id}`);

      setProject(data.project);
    };

    handleFetch();
  }, []);

  return (
    <Modal
      motionPreset="slideInBottom"
      size="full"
      isOpen={true}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{project?.title}</ModalHeader>
        <ModalCloseButton onClick={() => navigate("/portfolio")} />
        <ModalBody>
          <VStack
            w="95%"
            my={5}
            mx="auto"
            maxW="2xl"
            spacing={5}
            alignItems="flex-start"
          >
            <Image loading="lazy" src={project?.cover_photo} w="100%" />
            {project?.stack ? (
              <Stack>
                <Heading fontSize="1.5rem" fontWeight={600}>
                  Stack
                </Heading>
                <HStack wrap="wrap">
                  {project?.stack.map((_, index) => (
                    <StackCard key={index} props={{ title: _ }} />
                  ))}
                </HStack>
              </Stack>
            ) : null}
            {project?.tags ? (
              <Stack>
                <Heading fontSize="1.5rem" fontWeight={600}>
                  Tags
                </Heading>
                <HStack wrap="wrap">
                  {project?.tags.map((_, index) => (
                    <StackCard key={index} props={{ title: _ }} />
                  ))}
                </HStack>
              </Stack>
            ) : null}
            <Box
              dangerouslySetInnerHTML={{
                __html: project?.description as string,
              }}
            />
            <HStack>
              {project?.link ? (
                <Link
                  to={project.link}
                  target="_blank"
                  style={{ width: "100%" }}
                >
                  <Button bg="blue.300" w="100%" colorScheme="blue">
                    <HStack>
                      <Text>See live demo</Text>
                      <BsArrowRight size={20} />
                    </HStack>
                  </Button>
                </Link>
              ) : null}
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const StackCard = ({ props }: { props: { title: string } }) => {
  const { title } = props;

  return (
    <Text bg="blue.300" color="white" px={3} borderRadius={5}>
      {title}
    </Text>
  );
};

export default PortfolioModal;
