import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "../../components/Footer";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";

const Privacy = () => {
  const [render] = useRender();

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack spacing={5} textAlign="justify">
      {!render ? <Loader /> : null}
      <Header />
      <Stack w="100%" p={5} maxW="65rem" mx="auto" spacing={10}>
        <Stack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight={500}>
            Privacy Policy
          </Heading>
          <Heading fontSize="1.2rem" fontWeight={500}>
            Modern Village Privacy Policy
          </Heading>
          <Text>
            <b>Last Updated:</b> 23-11-2023
          </Text>
          <Text>
            Thank you for choosing Modern Village ("we," "us," or "our") for
            your technology needs. This Privacy Policy is designed to help you
            understand how we collect, use, disclose, and safeguard your
            personal information. By accessing or using our website
            (https://www.modernvillage.ng) or our services, you agree to the
            terms of this Privacy Policy.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            1. Information We Collect
          </Heading>
          <Text>
            We may collect the following types of personal information:
          </Text>
          <Text>
            <b>Personal Identifiers</b>: Name, email address, phone number, and
            other contact information.
          </Text>
          <Text>
            <b>Client Information: </b>
            Information provided by clients for the purpose of project
            development, including project specifications and requirements.
          </Text>
          <Text>
            <b>Payment Information: </b>
            If applicable, we may collect billing and payment information.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            2. How We Use Your Information
          </Heading>
          <Text>
            We may use your personal information for the following purposes:
          </Text>
          <Text>
            <b>Providing Services</b>: To fulfill your requests for our
            services, including mobile app development, web development, API
            development, cloud computing, IoT, IT consultancy, product
            management, and product design.
          </Text>
          <Text>
            <b>Communication: </b>
            To communicate with you about our services, updates, and important
            notices.
          </Text>
          <Text>
            <b>Analytics: </b>
            To analyze and improve the performance and functionality of our
            website and services.
          </Text>
          <Text>
            <b>Legal Compliance: </b>
            To comply with applicable laws and regulations.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            3. Information Sharing
          </Heading>
          <Text>
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            described in this Privacy Policy. We may share your information
            with:
          </Text>
          <Text>
            <b>Service Providers</b>: Third-party service providers who assist
            us in operating our website and providing our services.
          </Text>
          <Text>
            <b>Legal Requirements: </b>
            To comply with legal obligations, such as responding to subpoenas or
            court orders.
          </Text>
          <Text>
            <b>Business Transfers: </b>
            In the event of a merger, acquisition, or sale of all or a portion
            of our assets, your information may be transferred to the acquiring
            entity.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            4. Security
          </Heading>
          <Text>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, alteration, and
            destruction. However, no method of transmission over the internet or
            electronic storage is completely secure, and we cannot guarantee
            absolute security.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            5. Your Choices
          </Heading>
          <Text>You have the right to:</Text>
          <Text>
            <b>Access</b>: Request access to the personal information we hold
            about you.
          </Text>
          <Text>
            <b>Correction: </b>
            Request correction of inaccuracies in your personal information.
          </Text>
          <Text>
            <b>Deletion: </b>
            Request the deletion of your personal information, subject to legal
            restrictions.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            6. Changes to this Privacy Policy
          </Heading>
          <Text>
            We may update this Privacy Policy from time to time. The date of the
            latest revision will be indicated at the top of the policy.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            Contact Us
          </Heading>
          <Text>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at{" "}
            <Link title="Open Gmail app" color="blue.500">
              hello@modernvillage.ng
            </Link>
            . Thank you for trusting Modern Village with your technology needs.
          </Text>
        </Stack>
      </Stack>
      <Footer theme="dark" />
    </Stack>
  );
};

export default Privacy;
