import {
  Flex,
  Heading,
  HStack,
  IconButton,
  VStack,
  Text,
  Stack,
  Button,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { BsFacebook, BsTwitter, BsLinkedin, BsTelegram } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Footer = ({ theme }: { theme?: string }) => {
  const styles = {
    bg: theme && theme === "dark" ? "black" : "white",
    color: theme && theme === "dark" ? "whiteAlpha.800" : "black",
  };

  const navigate = useNavigate();

  return (
    <Flex
      {...styles}
      flexWrap="wrap"
      px={{ base: "1rem", md: "3.5rem" }}
      rowGap={8}
      py={10}
    >
      <Stack spacing={5} w={{ base: "100%", md: "50%" }}>
        <Heading fontSize="1.5rem" fontWeight="500">
          Find Us
        </Heading>
        <HStack color="inherit">
          <IconButton
            variant="unstyled"
            color="inherit"
            aria-label=""
            bg="transparent"
            icon={<BsFacebook />}
          />
          <IconButton
            variant="unstyled"
            color="inherit"
            aria-label=""
            bg="transparent"
            icon={<BsTwitter />}
          />
          <IconButton
            variant="unstyled"
            color="inherit"
            aria-label=""
            bg="transparent"
            icon={<BsLinkedin />}
          />
          <IconButton
            variant="unstyled"
            color="inherit"
            aria-label=""
            bg="transparent"
            icon={<BsTelegram />}
          />
        </HStack>
      </Stack>
      <HStack
        w={{ base: "100%", md: "50%" }}
        justifyContent="space-between"
        alignItems="flex-start"
        wrap={{ base: "wrap", md: "nowrap" }}
        rowGap={10}
      >
        <VStack w={{ base: "100%", md: "33%" }} alignItems="left" spacing={5}>
          <Heading fontSize={{ base: "1.2rem", md: "1.5rem" }} fontWeight="500">
            COMPANY
          </Heading>
          <Link href="/about" title="Go to about us">
            About us
          </Link>
          <Link href="/contact" title="Go to contact us">
            Contact
          </Link>
        </VStack>
        <VStack w={{ base: "100%", md: "33%" }} alignItems="left" spacing={5}>
          <Heading fontSize={{ base: "1.2rem", md: "1.5rem" }} fontWeight="500">
            SERVICES
          </Heading>
          <Link href="/services">Mobile app development</Link>
          <Link href="/services">Web development</Link>
          <Link href="/services">API development</Link>
          <Link href="/services">Cloud Computing</Link>
          <Link href="/services">iOT</Link>
          <Link href="/services">IT Consultancy</Link>
          <Link href="/services">Product management</Link>
          <Link href="/services">Product design</Link>
        </VStack>
        <VStack w={{ base: "100%", md: "33%" }} alignItems="left" spacing={5}>
          <Heading fontSize={{ base: "1.2rem", md: "1.5rem" }} fontWeight="500">
            OTHERS
          </Heading>
          <Link href="/privacy" title="Go to privacy policy">
            Privacy Policy
          </Link>
          <Link href="/terms" title="Go to terms & conditions">
            Terms & Conditions
          </Link>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default Footer;
