import { GridItem, Stack, Flex, Heading, Text, Image } from "@chakra-ui/react";

const Innovation = () => {
  return (
    <GridItem
      colStart={{ md: 3 }}
      colSpan={{ md: 4 }}
      rowStart={1}
      rowEnd={{ md: 10 }}
      h="100%"
    >
      <Stack
        h="100%"
        p={5}
        bg="white"
        boxShadow="sm"
        borderWidth="1px"
        borderRadius={5}
        borderColor="blackAlpha.300"
        spacing={5}
      >
        <Flex w="4rem" h="4rem" borderRadius="100%" bg="gray.100" p={4}>
          <Image src="/solution.png" />
        </Flex>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Innovation at the Core
        </Heading>
        <Text>
          Innovation is the lifeblood of Modern Village. We believe in pushing
          the boundaries of what technology can achieve. Our team of skilled
          developers, designers, and tech enthusiasts work collaboratively to
          bring forth groundbreaking solutions. Whether it's developing
          intuitive mobile applications, robust software systems, or
          cutting-edge tech products, we strive to stay ahead of the curve.
        </Text>
      </Stack>
    </GridItem>
  );
};

export default Innovation;
