import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Bank = () => {
  return (
    <Box borderWidth="1px" borderColor="blackAlpha.300" borderRadius={5}>
      <Heading
        borderBottomWidth="1px"
        borderColor="blackAlpha.300"
        color="blackAlpha.700"
        px={4}
        py={3}
        fontSize="1.2rem"
        fontWeight="500"
      >
        Bank
      </Heading>
      <Flex flexWrap="wrap" rowGap={5} alignItems="left" py={5} px={4}>
        <DetailCard props={{ title: "Bank", info: "Polaris Bank" }} />
        <DetailCard props={{ title: "Account Number", info: "01010101010" }} />
        <DetailCard props={{ title: "Account Type", info: "Savings" }} />
        <DetailCard props={{ title: "Number Of Times Paid", info: "23" }} />
      </Flex>
    </Box>
  );
};

const DetailCard = ({ props }: { props: { title: string; info: string } }) => {
  const { title, info } = props;

  return (
    <Box w="50%">
      <Heading fontSize="1.2rem" fontWeight="500">
        {title}
      </Heading>
      <Text>{info}</Text>
    </Box>
  );
};

export default Bank;
