import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";

const MobileDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from("#links a", {
      x: -20,
      opacity: 0,
      duration: 1,
      stagger: 0.1,
    });
  });

  const handleClose = () => {
    onClose();

    const hamburger = document.getElementById("hamburger");

    !isOpen
      ? hamburger != null && hamburger.classList.add("active")
      : hamburger != null && hamburger.classList.remove("active");
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="full">
      <DrawerContent>
        <DrawerBody bg="blue.500" color="white">
          <Stack w="100%" spacing={8} py={28}>
            <Heading fontWeight={600} fontSize="1.1rem">
              Navigation
            </Heading>
            <Stack pl={4} spacing={5} alignItems="flex-start" id="links">
              <Link
                onClick={handleClose}
                variant="primary"
                href="/"
                title="Go back home"
              >
                Home
              </Link>
              <Link
                onClick={handleClose}
                variant="primary"
                href="/about"
                title="More about us"
              >
                About us
              </Link>
              <Link
                onClick={handleClose}
                variant="primary"
                href="/contact"
                title="Send us an email"
              >
                Contact us
              </Link>
              <Link
                onClick={handleClose}
                variant="primary"
                href="/"
                title="Check out our services"
              >
                Our services
              </Link>
              <Link
                onClick={handleClose}
                variant="primary"
                href="/portfolio"
                title="See our portfolio"
              >
                Our portfolio
              </Link>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileDrawer;
