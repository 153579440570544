import { Flex, Grid, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/Header";
import { useEffect } from "react";
import Footer from "../../components/Footer";

import JoinUsOnJourney from "./JoinUsOnJourney";
import OurHistory from "./OurHistory";
import OurFounder from "./OurFounder";
import OurCommitment from "./OurCommitment";
import Innovation from "./Innovation";
import Empowering from "./Empowering";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";

gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const [render] = useRender();

  useEffect(() => {
    document.title = "Modern Village | Landing Page";
  }, []);

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Flex flexDir="column" overflow="hidden">
      {!render ? <Loader /> : null}
      <Header />
      <ModernVillage />
      <JoinUsOnJourney />
      <Footer />
    </Flex>
  );
};

const ModernVillage = () => {
  useGSAP(() => {
    gsap.from("#anime-cards01 div", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: "#anime-cards01",
        start: "top 70%",
        end: "bottom center",
        scrub: false,
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.from("#anime-cards02 div", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: "#anime-cards02",
        start: "top 70%",
        end: "bottom center",
        toggleActions: "play reverse play reverse",
      },
    });
  });

  return (
    <VStack
      spacing={5}
      w="100%"
      bgImage="/about-bg.jpg"
      p={5}
      bgSize="cover"
      py={20}
    >
      <Heading fontSize="1.5rem" fontWeight="600">
        WE ARE MODERN VILLAGE
      </Heading>
      <Text maxW="40rem" textAlign="center" fontSize={{ md: "1.3rem" }}>
        Our commitment to international standards makes us special.
      </Text>
      <Stack
        w={{ base: "100%", md: "fit-content" }}
        maxW="75rem"
        py={10}
        spacing={8}
      >
        <Grid
          id="anime-cards01"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
          templateRows={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
          gap={8}
        >
          <OurHistory />
          <OurFounder />
          <OurCommitment />
        </Grid>
        <Grid
          id="anime-cards02"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
          templateRows={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
          gap={8}
        >
          <Innovation />
          <Empowering />
        </Grid>
      </Stack>
    </VStack>
  );
};

export default Homepage;
