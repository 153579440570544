import {
  useMediaQuery,
  VStack,
  Heading,
  Button,
  HStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { BsPeopleFill } from "react-icons/bs";
import { MdArrowRightAlt, MdCall } from "react-icons/md";
import ActionCard from "./ActionCard";
import { useNavigate } from "react-router-dom";

const JoinUsOnJourney = () => {
  const [mobile] = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();

  return (
    <Box pb="8rem" pos="relative">
      <Box p={{ base: "1rem", md: "3.5rem" }} bg="blackAlpha.800">
        <VStack spacing={8} alignItems="left" color="white">
          <Heading fontSize="1.5rem" fontWeight="500">
            Join us on the Journey
          </Heading>
          <Text maxW="md" color="whiteAlpha.800" textAlign="justify">
            As we continue on our journey to shape the future of technology, we
            invite you to join us. Whether you are a business looking for
            innovative solutions or a tech enthusiast seeking a dynamic and
            challenging environment, Modern Village is the place where ideas
            come to life, and innovation knows no bounds.
          </Text>
          <Button
            w="fit-content"
            colorScheme="blue"
            variant="unstyled"
            rightIcon={<MdArrowRightAlt />}
            onClick={() => navigate("/portfolio")}
          >
            See projects
          </Button>
        </VStack>
      </Box>
      {mobile ? (
        <VStack w="100%" py={5} px={3} spacing={5}>
          <Button
            w="100%"
            size="lg"
            onClick={() => navigate("/contact/#phone")}
          >
            BOOK A CALL
          </Button>
        </VStack>
      ) : (
        <HStack w="md" pos="absolute" right="3.5rem" bottom={0} h="14rem">
          <ActionCard
            icon={<MdCall size={25} color="white" />}
            link="/contact"
            title="Book a Call"
          />
          <ActionCard
            icon={<BsPeopleFill size={25} color="white" />}
            link="/portfolio"
            title="Get Demo"
          />
        </HStack>
      )}
    </Box>
  );
};

export default JoinUsOnJourney;
