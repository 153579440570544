import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      primary: "red",
      blue: "#098DD7",
    },
  },
  styles: {
    global: {
      "*": {
        fontFamily: "Adamina",
        "::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "::-webkit-scrollbar-track": {
          bg: "white",
        },
        "::-webkit-scrollbar-thumb": {
          bg: "blue.500",
          borderRadius: 20,
        },
      },
    },
  },
  components: {
    Button: {
      variants: {
        "custom-link": {
          // color: "black",
          fontWeight: 400,
          width: "fit-content",
          height: "fit-content",
          padding: 0,
        },
        "custom-solid": {
          background: "linear-gradient(to right, #737373 50%, transparent 50%)",
          backgroundSize: "200% 100%",
          backgroundPosition: "right bottom",
          transition: "all .5s ease-out",
          color: "white",
          border: "1px solid white",
          _hover: {
            backgroundPosition: "left bottom",
            color: "white",
          },
        },
      },
    },
  },
});
