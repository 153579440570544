import { GridItem, Stack, Flex, Icon, Heading, Text } from "@chakra-ui/react";
import { IoReload } from "react-icons/io5";

const OurHistory = () => {
  return (
    <GridItem
      id="history"
      colSpan={{ md: 4 }}
      rowStart={{ md: 1 }}
      rowEnd={{ md: 10 }}
    >
      <Stack
        h="100%"
        p={5}
        bg="white"
        boxShadow="sm"
        borderWidth="1px"
        borderRadius={5}
        borderColor="blackAlpha.300"
        spacing={5}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          w="4rem"
          h="4rem"
          borderRadius="100%"
          bg="green.100"
          p={4}
        >
          {/* <Image src="/our-founder.svg" /> */}
          <Icon color="green" as={IoReload} boxSize={25} />
        </Flex>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Our History
        </Heading>
        <Text>
          Founded on the principles of excellence, creativity, and a commitment
          to pushing the boundaries of what is possible in the tech world,
          Modern Village is at the forefront of driving technological
          advancements. With a vision to empower businesses and individuals
          through technology, we strive to create solutions that not only meet
          the needs of our clients but also set new benchmarks in the industry.
        </Text>
      </Stack>
    </GridItem>
  );
};

export default OurHistory;
