import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import baseURL from "../../utils/baseURL";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  return (
    <Flex
      w="100%"
      minH="100vh"
      alignItems="center"
      justifyContent="center"
      bgImage="/login-bg-pattern.svg"
      p={5}
    >
      <Stack
        bg="whiteAlpha.800"
        w="2xl"
        p={10}
        borderRadius={5}
        alignItems="center"
        spacing={8}
      >
        <Heading fontSize="1.5rem" fontWeight={600}>
          Welcome back!
        </Heading>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const { data } = await axios.post(`${baseURL}/admin/login`, {
              email: values.email,
              password: values.password,
            });

            setSubmitting(false);

            if (data.status === true) {
              localStorage.setItem("token", data.token);
              navigate("/admin/portfolio");
            } else
              toast({
                title: "Request couldn't be completed",
                description:
                  "We encountered an error while logging you in. Please try again.",
                isClosable: true,
                status: "warning",
                duration: 3000,
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack
                w={{ base: "100%", md: "md" }}
                alignItems="center"
                spacing={5}
              >
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  type="email"
                  borderColor="blue.500"
                  _hover={{ borderColor: "blue.500" }}
                  placeholder="Email address"
                />
                {errors.email && touched.email && errors.email}
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  type="password"
                  borderColor="blue.500"
                  _hover={{ borderColor: "blue.500" }}
                  placeholder="Password"
                />
                {errors.password && touched.password && errors.password}
                <Button
                  type="submit"
                  leftIcon={<BsArrowRight />}
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Logging in. Please wait..."
                >
                  Proceed
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default Login;
