import React, { useEffect, useState } from "react";

const useRender = () => {
  const [render, setRender] = useState<string | null>(null);

  const handleRender = () => {
    const width = window.innerWidth;

    setTimeout(() => {
      if (width < 768) return setRender("mobile");
      // if (width > 600 && width < 960) return setRender("tablet");
      if (width > 767) return setRender("desktop");
    }, 1500);
  };

  useEffect(() => {
    handleRender();

    window?.addEventListener("resize", handleRender);
  }, []);

  return [render];
};

export default useRender;
