import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Header from "./Header";
import { Testimonial } from "../services/Services";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const [render] = useRender();

  useEffect(() => {
    document.title = "Modern Village | About";
  }, []);

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack spacing={20}>
      {!render ? <Loader /> : null}
      <Header />
      <WhoWeAre />
      <LearnMore />
      {/* <Testimonial /> */}
      <Footer theme="dark" />
    </Stack>
  );
};

const WhoWeAre = () => {
  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from("#img1", {
      autoAlpha: 0,
      y: 30,
      duration: 1,
      stagger: 0.1,
    });

    tl.from("#img2", {
      autoAlpha: 0,
      y: -30,
      duration: 1,
      stagger: 0.1,
    });

    tl.from("#main h2", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
    });

    tl.from("#main p", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
    });

    tl.from("#main div", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
    });
  });

  return (
    <Grid
      id="start"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      w={{ base: "100%", md: "100%" }}
      maxW="75rem"
      mx="auto"
      gap={20}
    >
      <Flex id="images" gap={5} display={{ base: "none", md: "flex" }}>
        <Image
          id="img1"
          borderRadius={5}
          h="90%"
          w="50%"
          objectFit="cover"
          objectPosition="center"
          src="/business-people-discussing.jpg"
          alignSelf="flex-start"
        />
        <Image
          id="img2"
          borderRadius={5}
          h="90%"
          w="50%"
          objectFit="cover"
          objectPosition="right"
          src="/asian-man-using-laptop.jpg"
          alignSelf="flex-end"
        />
      </Flex>
      <Stack id="main" spacing={8} textAlign="justify" p={{ base: 5, md: 0 }}>
        <Heading fontSize="1rem" fontWeight={500} color="blue.500">
          ABOUT US
        </Heading>
        <Heading fontSize="1.5rem" fontWeight={600}>
          Welcome to Modern Village, a cutting-edge technology company.
        </Heading>
        <Text>
          Founded on the principles of excellence, creativity, and a commitment
          to pushing the boundaries of what is possible in the tech world,
          Modern Village is at the forefront of driving technological
          advancements.
        </Text>
        <Box h="1px" w="100%" bg="blackAlpha.400" />
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={10}
        >
          <Stack>
            <Heading fontSize="1.25rem" fontWeight={500}>
              Our Mission
            </Heading>
            <Text>
              To elevate the technological landscape in Nigeria and beyond by
              developing innovative apps and systems that meet and exceed
              international standards.
            </Text>
          </Stack>
          <Stack>
            <Heading fontSize="1.25rem" fontWeight={500}>
              Our Vision
            </Heading>
            <Text>
              To empower businesses and individuals through technology, we
              strive to create solutions that not only meet the needs of our
              clients but also set new benchmarks in the industry.
            </Text>
          </Stack>
        </Grid>
      </Stack>
    </Grid>
  );
};

const LearnMore = () => {
  return (
    <Center w="100%" p={5} bgSize="cover">
      <Stack spacing={8} w="100%" maxW="75rem" mx="auto" alignItems="center">
        <Heading fontSize="1rem" fontWeight={500} color="blue.500">
          LEARN MORE ABOUT US
        </Heading>
        <Heading
          fontSize="2rem"
          fontWeight={500}
          w="100%"
          maxW="md"
          textAlign="center"
        >
          We provide truly prominent IT solutions.
        </Heading>
        <Stack spacing={8} w={{ base: "100%", md: "fit-content" }}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
            templateRows={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
            gap={8}
          >
            <GridItem
              colSpan={{ md: 4 }}
              rowStart={{ md: 1 }}
              rowEnd={{ md: 10 }}
            >
              <Stack
                h="100%"
                p={5}
                bg="white"
                boxShadow="sm"
                borderWidth="1px"
                borderRadius={5}
                borderColor="blackAlpha.300"
                spacing={5}
              >
                <Flex
                  w="4rem"
                  h="4rem"
                  borderRadius="100%"
                  bg="orange.100"
                  p={4}
                >
                  <Image src="/our-founder.svg" />
                </Flex>
                <Heading fontSize="1.5rem" fontWeight={500}>
                  Our Founder
                </Heading>
                <Text>
                  At the heart of Modern Village is our visionary founder,
                  Orishani Ijeomah Joseph. His passion for technology, coupled
                  with a relentless pursuit of excellence, has been the driving
                  force behind our company's success. With a wealth of
                  experience and a deep understanding of the ever-evolving tech
                  landscape, Ijeomah Joseph leads our team in crafting solutions
                  that make a lasting impact.
                </Text>
              </Stack>
            </GridItem>
            <GridItem colSpan={{ md: 4 }} rowStart={2} rowEnd={{ md: 11 }}>
              <Stack
                h="100%"
                p={5}
                bg="white"
                boxShadow="sm"
                borderWidth="1px"
                borderRadius={5}
                borderColor="blackAlpha.300"
                spacing={5}
              >
                <Flex w="4rem" h="4rem" borderRadius="100%" bg="red.100" p={4}>
                  <Image src="/commitment.svg" />
                </Flex>
                <Heading fontSize="1.5rem" fontWeight={500}>
                  Our Commitment to International Standards
                </Heading>
                <Text>
                  We understand the importance of aligning with global standards
                  in an increasingly interconnected world. At Modern Village, we
                  are dedicated to developing apps and systems that not only
                  meet but surpass international benchmarks. Our commitment to
                  quality assurance, security, and user experience ensures that
                  our products are not only technologically advanced but also
                  reliable and user-friendly.
                </Text>
              </Stack>
            </GridItem>
            <GridItem
              colSpan={{ md: 4 }}
              rowStart={3}
              rowEnd={{ md: 12 }}
              h="100%"
            >
              <Stack
                h="100%"
                p={5}
                bg="white"
                boxShadow="sm"
                borderWidth="1px"
                borderRadius={5}
                borderColor="blackAlpha.300"
                spacing={5}
              >
                <Flex w="4rem" h="4rem" borderRadius="100%" bg="gray.100" p={4}>
                  <Image src="/solution.png" />
                </Flex>
                <Heading fontSize="1.5rem" fontWeight={500}>
                  Innovation at the Core
                </Heading>
                <Text>
                  Innovation is the lifeblood of Modern Village. We believe in
                  pushing the boundaries of what technology can achieve. Our
                  team of skilled developers, designers, and tech enthusiasts
                  work collaboratively to bring forth groundbreaking solutions.
                  Whether it's developing intuitive mobile applications, robust
                  software systems, or cutting-edge tech products, we strive to
                  stay ahead of the curve.
                </Text>
              </Stack>
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
            templateRows={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
            gap={8}
          >
            <GridItem
              colStart={{ md: 3 }}
              colSpan={{ md: 4 }}
              rowStart={{ md: 1 }}
              rowEnd={{ md: 10 }}
            >
              <Stack
                h="100%"
                p={5}
                bg="white"
                boxShadow="sm"
                borderWidth="1px"
                borderRadius={5}
                borderColor="blackAlpha.300"
                spacing={5}
              >
                <Flex
                  w="4rem"
                  h="4rem"
                  borderRadius="100%"
                  bg="purple.100"
                  p={4}
                >
                  <Image src="/business.png" />
                </Flex>
                <Heading fontSize="1.5rem" fontWeight={500}>
                  Empowering Businesses and Individuals
                </Heading>
                <Text>
                  At Modern Village, we believe in the transformative power of
                  technology to empower both businesses and individuals. Our
                  solutions are designed to streamline processes, enhance
                  efficiency, and provide a competitive edge in the digital
                  landscape. We work closely with our clients to understand
                  their unique needs and tailor our solutions to drive success
                  in their respective domains.
                </Text>
              </Stack>
            </GridItem>
            <GridItem
              colStart={{ md: 7 }}
              colSpan={{ md: 4 }}
              rowStart={{ md: 2 }}
              rowEnd={{ md: 11 }}
              h="100%"
            >
              <Stack
                h="100%"
                p={5}
                bg="white"
                boxShadow="sm"
                borderWidth="1px"
                borderRadius={5}
                borderColor="blackAlpha.300"
                spacing={5}
              >
                <Flex
                  w="4rem"
                  h="4rem"
                  borderRadius="100%"
                  bg="green.100"
                  p={4}
                >
                  <Image src="/journey.png" />
                </Flex>
                <Heading fontSize="1.5rem" fontWeight={500}>
                  Join us on the Journey
                </Heading>
                <Text>
                  As we continue on our journey to shape the future of
                  technology, we invite you to join us. Whether you are a
                  business looking for innovative solutions or a tech enthusiast
                  seeking a dynamic and challenging environment, Modern Village
                  is the place where ideas come to life, and innovation knows no
                  bounds.
                </Text>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </Center>
  );
};

export default About;
