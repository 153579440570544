import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Text,
  keyframes,
} from "@chakra-ui/react";
import React from "react";

const scale = keyframes`  
  from {transform: scale(0.1);}   
  to {transform: scale(1)} 
`;

const SocialsCard = ({ title, icon }: { title: string; icon: JSX.Element }) => {
  const scaleAnimation = `${scale} 0.5s linear`;

  return (
    <Box id="button">
      <IconButton
        h="2.5rem"
        w="2.5rem"
        p={2}
        border="2px solid white"
        borderRadius="100%"
        variant="unstyled"
        color="inherit"
        aria-label=""
        bg="transparent"
        pos="relative"
        icon={icon}
        _hover={{
          border: "2px solid",
          borderColor: "blue.500",
          color: "blue.500",
          _before: {
            content: `"${title}"`,
            position: "absolute",
            right: 12,
            top: "25%",
            my: "auto",
            fontSize: "0.9rem",
            fontWeight: 400,
            color: "blue.500",
            animation: scaleAnimation,
            transitionTimingFunction: "cubic-bezier(0,.71,.39,1.11)",
            transformOrigin: "right center",
          },
        }}
      />
    </Box>
  );
};

export default SocialsCard;
