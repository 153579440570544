import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "../../components/Footer";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";

const Terms = () => {
  const [render] = useRender();

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack spacing={5} textAlign="justify">
      {!render ? <Loader /> : null}
      <Header />
      <Stack w="100%" p={5} maxW="65rem" mx="auto" spacing={5}>
        <Stack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight={500}>
            Terms And Condition
          </Heading>
          <Heading fontSize="1.2rem" fontWeight={500}>
            Modern Village: Terms and Conditions
          </Heading>
          <Text>
            <b>Effective Date:</b> 23-11-2023
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            1. Acceptance of Terms
          </Heading>
          <Text>
            Welcome to Modern Village ("Modern Village"). By accessing or using
            our services, including but not limited to mobile app development,
            web development, API development, cloud computing, IoT, IT
            consultancy, product management, and product design (collectively
            referred to as the "Services"), you agree to comply with and be
            bound by these Terms and Conditions.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            2. Services
          </Heading>
          <Text>
            Modern Village offers a range of technology services as described on
            our website: https://www.modernvillage.ng. These services may be
            updated, modified, or expanded at our discretion.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            3. Payments
          </Heading>
          <Text>
            Payment terms for our Services will be outlined in the specific
            agreements or contracts for each project. Modern Village adheres to
            Nigerian regulations regarding invoicing, taxes, and financial
            transactions.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            4. Confidentiality
          </Heading>
          <Text>
            Modern Village understands the importance of confidentiality. Any
            information shared with us during the course of providing our
            services will be treated with the utmost confidentiality. We comply
            with Nigerian regulations regarding data protection and privacy.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            5. Intellectual Property
          </Heading>
          <Text>
            Any intellectual property created during the provision of our
            services, including but not limited to software, designs, and
            content, will be the property of Modern Village unless otherwise
            specified in writing.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            6. Compliance with Laws
          </Heading>
          <Text>
            Modern Village operates in accordance with Nigerian regulations and
            expects users of our Services to do the same. Users are responsible
            for complying with all applicable laws and regulations.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            7. Limitation of Liability
          </Heading>
          <Text>
            Modern Village is not liable for any indirect, incidental, special,
            consequential, or punitive damages, or any loss of profits or
            revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            8. Termination
          </Heading>
          <Text>
            We reserve the right to terminate or suspend access to our Services
            at our sole discretion, without notice, for conduct that we believe
            violates these Terms and Conditions or is harmful to our Services or
            third parties or for any other reason.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.2rem" fontWeight={500}>
            9. Changes to Terms
          </Heading>
          <Text>
            Modern Village reserves the right to update or modify these Terms
            and Conditions at any time. Any changes will be effective
            immediately upon posting on our website.
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Text>
            By using our Services, you agree to be bound by the most current
            version of these Terms and Conditions.
          </Text>
          <Text>
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at{" "}
            <Link href="mailto:hello@modernvillage.ng" color="blue.500">
              hello@modernvillage.ng
            </Link>
            .
          </Text>
          <Text>Thank you for choosing Modern Village!</Text>
        </Stack>
      </Stack>
      <Footer theme="dark" />
    </Stack>
  );
};

export default Terms;
