import { GridItem, Stack, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";

const OurFounder = () => {
  return (
    <GridItem colSpan={{ md: 4 }} rowStart={{ md: 2 }} rowEnd={{ md: 11 }}>
      <Stack
        h="100%"
        p={5}
        bg="white"
        boxShadow="sm"
        borderWidth="1px"
        borderRadius={5}
        borderColor="blackAlpha.300"
        spacing={5}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          w="4rem"
          h="4rem"
          borderRadius="100%"
          bg="orange.100"
          p={4}
        >
          <Image src="/our-founder.svg" />
        </Flex>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Our Founder
        </Heading>
        <Text>
          At the heart of Modern Village is our visionary founder, Orishani
          Ijeomah Joseph. His passion for technology, coupled with a relentless
          pursuit of excellence, has been the driving force behind our company's
          success. With a wealth of experience and a deep understanding of the
          ever-evolving tech landscape, Ijeomah Joseph leads our team in
          crafting solutions that make a lasting impact.
        </Text>
      </Stack>
    </GridItem>
  );
};

export default OurFounder;
