import { Heading, VStack } from "@chakra-ui/react";
import { AreaChart, Card, Title } from "@tremor/react";
import React from "react";

const Analytics = () => {
  return (
    <VStack
      alignItems="left"
      spacing={5}
      maxW="70rem"
      mx="auto"
      bg="white"
      m={5}
      boxShadow="sm"
      p={5}
    >
      <Heading fontSize="1.5rem" fontWeight="600">
        Monthly View
      </Heading>
      <MonthlyGraph />
      <Heading fontSize="1.5rem" fontWeight="600">
        Weekly View
      </Heading>
      <WeeklyGraph />
      <Heading fontSize="1.5rem" fontWeight="600">
        Daily View
      </Heading>
      <DailyGraph />
    </VStack>
  );
};

const chartdata = [
  {
    date: "Jan 22",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "Feb 22",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "Mar 22",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "Apr 22",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
  {
    date: "May 22",
    "Data Traffic": 3475,
    Impressions: 1812,
  },
  {
    date: "Jun 22",
    "Data Traffic": 3129,
    Impressions: 1726,
  },
];

const weeklychartdata = [
  {
    date: "31 Oct",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "7 Nov",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "14 Nov",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "21 Nov",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
];

const dailychartdata = [
  {
    date: "01 Nov",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "02 Nov",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "03 Nov",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "04 Nov",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
  {
    date: "05 Nov",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "06 Nov",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "07 Nov",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "08 Nov",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
  {
    date: "09 Nov",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "10 Nov",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "11 Nov",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "12 Nov",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
  {
    date: "13 Nov",
    "Data Traffic": 2890,
    Impressions: 2338,
  },
  {
    date: "14 Nov",
    "Data Traffic": 2756,
    Impressions: 2103,
  },
  {
    date: "15 Nov",
    "Data Traffic": 3322,
    Impressions: 2194,
  },
  {
    date: "16 Nov",
    "Data Traffic": 3470,
    Impressions: 2108,
  },
];

const MonthlyGraph = () => {
  return (
    <Card>
      <Title>Traffic Charts</Title>
      <AreaChart
        data={chartdata}
        categories={["Data Traffic", "Impressions"]}
        dataKey="date"
        height="h-72"
        colors={["indigo", "cyan"]}
        marginTop="mt-4"
      />
    </Card>
  );
};

const WeeklyGraph = () => {
  return (
    <Card>
      <Title>Traffic Charts</Title>
      <AreaChart
        data={weeklychartdata}
        categories={["Data Traffic", "Impressions"]}
        dataKey="date"
        height="h-72"
        colors={["indigo", "cyan"]}
        marginTop="mt-4"
      />
    </Card>
  );
};

const DailyGraph = () => {
  return (
    <Card>
      <Title>Traffic Charts</Title>
      <AreaChart
        data={dailychartdata}
        categories={["Data Traffic", "Impressions"]}
        dataKey="date"
        height="h-72"
        colors={["indigo", "cyan"]}
        marginTop="mt-4"
      />
    </Card>
  );
};

export default Analytics;
