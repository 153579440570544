import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  Icon,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Header from "./Header";
import {
  BsBrowserChrome,
  BsChat,
  BsCloud,
  BsFillPlugFill,
  BsHeadset,
  BsLightbulb,
  BsPhoneFlip,
} from "react-icons/bs";
import { FaPeopleCarry, FaInternetExplorer } from "react-icons/fa";
import { SiMaterialdesign } from "react-icons/si";
import Footer from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { MdOutlineEditNote } from "react-icons/md";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  const [render] = useRender();

  useEffect(() => {
    document.title = "Modern Village | Services";
  }, []);

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack spacing={20} bg="gray.100">
      {!render ? <Loader /> : null}
      <Header />
      <ServicesTab />
      <MakeRequests />
      {/* <Testimonial /> */}
      <Footer theme="dark" />
    </Stack>
  );
};

const ServicesTab = () => {
  useGSAP(() => {
    gsap.from("#start div", {
      autoAlpha: 0,
      y: 20,
      duration: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: "#start",
        start: "top 80%",
        toggleActions: "play reverse play reverse",
      },
    });
  });

  return (
    <Grid
      id="start"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
      p={{ base: 5, md: 0 }}
      w="100%"
      maxW="75rem"
      mx="auto"
      gap={5}
    >
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={BsPhoneFlip} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          Mobile app development
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={BsBrowserChrome} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          Web development
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={BsFillPlugFill} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          API development
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={BsCloud} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          Cloud Computing
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={FaInternetExplorer} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          iOT
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={FaPeopleCarry} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          IT Consultancy
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={MdOutlineEditNote} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          Product management
        </Heading>
      </Stack>
      <Stack
        p={5}
        spacing={5}
        borderWidth={1}
        borderColor="blackAlpha.300"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Icon as={SiMaterialdesign} color="blue.500" boxSize={30} />
        <Heading fontSize="1rem" fontWeight={500}>
          Product design
        </Heading>
      </Stack>
    </Grid>
  );
};

const MakeRequests = () => {
  return (
    <Flex
      py={{ base: 5, md: 8 }}
      px={{ base: 5, md: 28 }}
      w="100%"
      maxW="75rem"
      mx="auto"
      bgImage="/makerequest-background.jpg"
      bgSize="cover"
      bgPos="center"
      alignItems="center"
      justifyContent="center"
      gap={8}
      wrap={{ base: "wrap", md: "nowrap" }}
    >
      <Box alignSelf="stretch" my={4} w="0.15rem" bg="blue.500" />
      <Heading
        color="white"
        w="xs"
        fontSize="1.8rem"
        fontWeight={500}
        lineHeight={1.8}
      >
        To make request for further information, contact us
      </Heading>
      <Spacer display={{ base: "none", md: "block" }} />
      <Stack color="white" alignItems="center">
        <Icon as={BsHeadset} boxSize={35} />
        <Text>Call Us For Any Inquiry</Text>
        <Text color="blue.500" fontSize="1.5rem" fontWeight={500}>
          (+234) 08137779598
        </Text>
      </Stack>
    </Flex>
  );
};

export const Testimonial = () => {
  return (
    <Stack spacing={10} alignItems="center">
      <Heading color="blue.500" fontSize="1rem" fontWeight={500}>
        TESTIMONIAL
      </Heading>
      <Heading fontSize="1.5rem" fontWeight={500}>
        100+ satisfied clients worldwide
      </Heading>
      <Box overflow="hidden" w="100%" maxW="75rem" mx="auto">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={2}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={true}
          modules={[Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialCard />
          </SwiperSlide>
        </Swiper>
      </Box>
    </Stack>
  );
};

const TestimonialCard = () => {
  return (
    <Flex w="100%" pos="relative" justifyContent="flex-end">
      <Avatar
        display={{ base: "none", md: "block" }}
        name="John Doe"
        size="lg"
        borderRadius={5}
        pos="absolute"
        left={0}
        top={0}
        bottom={0}
        margin="auto"
      />
      <Stack
        w={{ base: "100%", md: "95%" }}
        bg="white"
        p={5}
        pl={{ base: 5, md: 20 }}
      >
        <Avatar
          display={{ base: "block", md: "none" }}
          name="John Doe"
          size="lg"
          borderRadius={5}
        />
        <Text display={{ base: "block", md: "none" }} fontWeight={600}>
          <b>John Doe</b> / CEO, Harlong inc
        </Text>
        <Icon as={BsChat} boxSize={5} display={{ base: "none", md: "block" }} />
        <Text>
          Accelerate innovation with world-class tech teams beyond more stoic
          this along goodness hey this is wow manatee
        </Text>
        <Text display={{ base: "none", md: "block" }} fontWeight={600}>
          <b>John Doe</b> / CEO, Harlong inc
        </Text>
      </Stack>
    </Flex>
  );
};

export default Services;
