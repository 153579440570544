import {
  Avatar,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const EmployeeDetails = () => {
  return (
    <Flex
      flexDir="column"
      gap={8}
      alignSelf="flex-start"
      p={5}
      w="calc(100% - 20rem)"
    >
      <Header />
      <Tabs />
      <Outlet />
    </Flex>
  );
};

const Header = () => {
  return (
    <HStack>
      <Avatar size="lg" name="Cody Fisher" />
      <VStack alignItems="flex-start">
        <HStack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight="600">
            Cody Fisher
          </Heading>
          <Text
            color="whatsapp.500"
            borderWidth="2px"
            borderColor="whatsapp.500"
            px={2}
            borderRadius={5}
            fontWeight="600"
          >
            Active
          </Text>
        </HStack>
        <Text color="blackAlpha.500" fontWeight="600">
          Design Team. UX designer.
        </Text>
      </VStack>
    </HStack>
  );
};

const Tabs = () => {
  return (
    <HStack>
      <NavButton props={{ title: "DETAILS", link: "/admin/employee/hahah" }} />
      <NavButton
        props={{ title: "BANK", link: "/admin/employee/hahah/bank" }}
      />
      <NavButton
        props={{ title: "CONTACT", link: "/admin/employee/hahah/contact" }}
      />
    </HStack>
  );
};

const NavButton = ({ props }: { props: { title: string; link: string } }) => {
  const navigate = useNavigate();
  const { title, link } = props;

  return <Button onClick={() => navigate(link)}>{title}</Button>;
};

export default EmployeeDetails;
