import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Contact = () => {
  return (
    <Box borderWidth="1px" borderColor="blackAlpha.300" borderRadius={5}>
      <Heading
        borderBottomWidth="1px"
        borderColor="blackAlpha.300"
        color="blackAlpha.700"
        px={4}
        py={3}
        fontSize="1.2rem"
        fontWeight="500"
      >
        Contact
      </Heading>
      <Flex flexWrap="wrap" rowGap={5} alignItems="left" py={5} px={4}>
        <DetailCard
          props={{
            title: "House Address",
            info: "B51, University Of Ibadan, Nigeria",
          }}
        />
        <DetailCard
          props={{ title: "Email Address", info: "example@gmail.com" }}
        />
        <DetailCard props={{ title: "Phone number", info: "07088115563" }} />
      </Flex>
    </Box>
  );
};

const DetailCard = ({ props }: { props: { title: string; info: string } }) => {
  const { title, info } = props;

  return (
    <Box w="50%">
      <Heading fontSize="1.2rem" fontWeight="500">
        {title}
      </Heading>
      <Text>{info}</Text>
    </Box>
  );
};

export default Contact;
