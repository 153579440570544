import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  Textarea,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import baseURL from "../../../utils/baseURL";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const steps = [
  { title: "Basic information" },
  { title: "Links" },
  { title: "Images" },
  { title: "Confirmation" },
];

const AddPortfolio = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [latestTag, setLatestTag] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [latestStack, setLatestStack] = useState("");
  const [stack, setStack] = useState<string[]>([]);
  const [link, setLink] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");

  const handleSteppers = () => {
    if (title && description && tags.length != 0 && activeStep == 0)
      setActiveStep(1);
    if (link && activeStep == 1) setActiveStep(2);
    if (coverPhoto && activeStep == 2) setActiveStep(3);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const { data } = await axios.post(
      `${baseURL}/project`,
      {
        title,
        status: "Ongoing",
        cover_photo: coverPhoto,
        description,
        link,
        tags,
        stack,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    );

    if (data.status === true) {
      toast({
        title: "Request successful",
        description:
          "Project successfully added. Navigating to projects shortly...",
        duration: 2000,
        isClosable: true,
        status: "success",
      });

      setTimeout(() => {
        navigate("/admin/portfolio");
      }, 2000);
    }

    setLoading(false);
  };

  return (
    <Flex bg="gray.200" h="100%" p={5} gap={10}>
      <Stepper
        p={5}
        w="fit-content"
        bg="white"
        index={activeStep}
        orientation="vertical"
        height="fit-content"
        gap={8}
        borderRadius={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator boxSize={6}>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} />
            </StepIndicator>
            <Box flexShrink={0}>
              <StepTitle>{step.title}</StepTitle>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Stack p={5} bg="white" flex={1} borderRadius={5} spacing={10}>
        <Stack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight={500}>
            Basic information
          </Heading>
          <Box h="1px" bg="blackAlpha.200" />
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              onChange={(e) => {
                setTitle(e.target.value);
                handleSteppers();
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Tags</FormLabel>
            <HStack>
              <Input
                value={latestTag}
                onChange={(e) => {
                  setLatestTag(e.target.value);
                  handleSteppers();
                }}
              />
              <Button
                colorScheme="blue"
                onClick={() => {
                  setTags([...tags, latestTag]);
                  setLatestTag("");
                }}
              >
                Add
              </Button>
            </HStack>
            <HStack pt={3}>
              {tags.map((el, index) => (
                <Button
                  key={index}
                  onClick={() =>
                    setTags((items) => items.filter((item) => item !== el))
                  }
                  variant="outline"
                  fontWeight={400}
                  _hover={{
                    bg: "red.100",
                  }}
                  rightIcon={<BsX size={20} />}
                >
                  {el}
                </Button>
              ))}
            </HStack>
          </FormControl>
          <FormControl>
            <FormLabel>Stack</FormLabel>
            <HStack>
              <Input
                value={latestStack}
                onChange={(e) => {
                  setLatestStack(e.target.value);
                  handleSteppers();
                }}
              />
              <Button
                colorScheme="blue"
                onClick={() => {
                  setStack([...stack, latestStack]);
                  setLatestStack("");
                }}
              >
                Add
              </Button>
            </HStack>
            <HStack pt={3}>
              {stack.map((el, index) => (
                <Button
                  key={index}
                  onClick={() =>
                    setStack((items) => items.filter((item) => item !== el))
                  }
                  variant="outline"
                  fontWeight={400}
                  _hover={{
                    bg: "red.100",
                  }}
                  rightIcon={<BsX size={20} />}
                >
                  {el}
                </Button>
              ))}
            </HStack>
          </FormControl>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight={500}>
            Links
          </Heading>
          <Box h="1px" bg="blackAlpha.200" />
          <FormControl>
            <FormLabel>Project link</FormLabel>
            <Input
              onChange={(e) => {
                setLink(e.target.value);
                handleSteppers();
              }}
            />
          </FormControl>
        </Stack>
        <Stack spacing={5}>
          <Heading fontSize="1.5rem" fontWeight={500}>
            Images
          </Heading>
          <Box h="1px" bg="blackAlpha.200" />
          <FormControl>
            <FormLabel>Cover Photo</FormLabel>
            <Input
              type="file"
              onChange={async (e) => {
                if (!e.target.files) return;

                toast({
                  title: "Request ongoing",
                  description: "Uploading image. Please wait...",
                  status: "info",
                  duration: 3000,
                  isClosable: true,
                });

                const { data } = await axios.postForm(
                  `${baseURL}/upload`,
                  {
                    asset: e.target.files[0],
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );

                if (data.asset.Location) {
                  setCoverPhoto(data.asset.Location);

                  toast({
                    title: "Request completed",
                    description:
                      "Image was successfully uploaded. You may now proceed.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            />
            {coverPhoto ? <Text pt={3}>URL: {coverPhoto}</Text> : null}
          </FormControl>
        </Stack>
        <Button
          alignSelf="flex-end"
          colorScheme="blue"
          onClick={handleSubmit}
          isDisabled={!title || !description}
          isLoading={loading}
          loadingText="Submitting..."
        >
          Submit
        </Button>
      </Stack>
    </Flex>
  );
};

export default AddPortfolio;
