import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Card, Title, DonutChart } from "@tremor/react";
import { BsPlusCircleFill, BsSearch, BsThreeDots } from "react-icons/bs";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../utils/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateProject } from "../../../features/projectSlice";

const Portfolio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFetch = async () => {
    const { data } = await axios.get(`${baseURL}/project`);
    dispatch(handleUpdateProject(data));
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Box bg="gray.50" h="100%">
      <HStack
        spacing={5}
        bg="white"
        px={5}
        py={3}
        boxShadow="sm"
        maxW="70rem"
        mx="auto"
      >
        <Heading fontSize="1.5rem" fontWeight="600">
          Projects
        </Heading>
        <Button
          onClick={() => navigate("/admin/portfolio/add")}
          colorScheme="telegram"
          bg="blue.300"
          leftIcon={<BsPlusCircleFill />}
        >
          Add Project
        </Button>
      </HStack>
      <Flex maxW="70rem" mx="auto" bg="white" m={5} boxShadow="sm" p={5}>
        <Projects />
        {/* <ProjectsGraphics /> */}
      </Flex>
    </Box>
  );
};

const Projects = () => {
  const project = useSelector((state: RootState) => state.project);

  return (
    <VStack w="100%" spacing={5} py={3}>
      <HStack w="70%">
        <InputGroup>
          <Input />
          <InputLeftElement>
            <BsSearch />
          </InputLeftElement>
        </InputGroup>
      </HStack>
      {project?.data?.projects?.map(
        (
          _: {
            title: string;
            status: string;
            _id: string;
            tags: string[];
            stack: string[];
          },
          index: number
        ) => (
          <ProjectCard
            _id={_._id}
            status={_.status}
            title={_.title}
            tags={_.tags}
            stack={_.stack}
          />
        )
      )}
    </VStack>
  );
};

const ProjectCard = ({
  _id,
  status,
  stack,
  title,
  tags,
}: {
  _id: string;
  status: string;
  tags: string[];
  stack: string[];
  title: string;
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteRef = useRef<any>();

  const handleMarkComplete = async () => {
    const { data } = await axios.put(
      `${baseURL}/project/${_id}`,
      { status: "Completed" },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    );

    if (data.status === true) {
      toast({
        title: "Request successful",
        description:
          "Project was marked as completed. Refreshing page soon. Please wait...",
        status: "success",
        isClosable: true,
        duration: 2000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const handleMarkOngoing = async () => {
    const { data } = await axios.put(
      `${baseURL}/project/${_id}`,
      { status: "Ongoing" },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    );

    if (data.status === true) {
      toast({
        title: "Request successful",
        description:
          "Project was marked as ongoing. Refreshing page soon. Please wait...",
        status: "success",
        isClosable: true,
        duration: 2000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const handleDeleteProject = async () => {
    const { data } = await axios.delete(`${baseURL}/project/${_id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });

    if (data.status === true) {
      toast({
        title: "Request successful",
        description:
          "Project was deleted. Refreshing page soon. Please wait...",
        status: "success",
        isClosable: true,
        duration: 2000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <HStack
      justifyContent="space-between"
      w="100%"
      px={5}
      py={2}
      borderBottomWidth="1px"
      borderColor="blackAlpha.200"
    >
      <VStack w="40%" alignItems="left" spacing={2}>
        <Heading fontSize="1rem" fontWeight="500">
          {title}
        </Heading>
        <HStack spacing={3}>
          {tags.map((el, index) => {
            return (
              <Text color="gray.500" fontSize="0.8rem" key={index}>
                {el}
              </Text>
            );
          })}
        </HStack>
      </VStack>
      <HStack w="20%" spacing={3}>
        {stack.map((el, index) => {
          return (
            <Text color="gray.500" fontSize="0.8rem" key={index}>
              {el}
            </Text>
          );
        })}
      </HStack>
      <Box w="20%">
        {status.toLowerCase() == "completed" ? (
          <Text
            w="fit-content"
            px={2}
            color="white"
            borderRadius={2}
            bg="blue.300"
          >
            Completed
          </Text>
        ) : (
          <Text
            w="fit-content"
            px={2}
            color="white"
            borderRadius={2}
            bg="yellow.400"
          >
            Ongoing
          </Text>
        )}
      </Box>
      <Menu>
        <MenuButton as={Button}>
          <BsThreeDots />
        </MenuButton>
        <MenuList>
          <MenuItem color="blue.500" onClick={handleMarkComplete}>
            Mark as complete
          </MenuItem>
          <MenuItem color="orange.300" onClick={handleMarkOngoing}>
            Mark as ongoing
          </MenuItem>
          <MenuItem onClick={() => navigate(`/admin/portfolio/${_id}`)}>
            Edit
          </MenuItem>
          <MenuItem color="red.500" onClick={onOpen}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={deleteRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete {title}?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete {title}? This action is
            irreversible.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={deleteRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleDeleteProject}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </HStack>
  );
};

const data = [
  {
    name: "Completed Projects",
    sales: 12,
  },
  {
    name: "Ongoing Projects",
    sales: 19,
  },
];

const valueFormatter = (number: number) => `${number.toString()} projects`;

const ProjectsGraphics = () => {
  const { data } = useSelector((state: RootState) => state.project);

  return (
    <Box w="40%">
      <Card maxWidth="max-w-lg">
        <Title>Projects by completion</Title>
        <DonutChart
          data={data?.projects}
          category="status"
          dataKey="title"
          valueFormatter={valueFormatter}
          marginTop="mt-6"
          colors={["blue", "red"]}
        />
      </Card>
      <VStack spacing={2} py={5}>
        <HStack>
          <Circle size=".5rem" bg="blue.600" />
          <Text>Completed Projects</Text>
        </HStack>
        <HStack>
          <Circle size=".5rem" bg="red.500" />
          <Text>Ongoing Projects</Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Portfolio;
