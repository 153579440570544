import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: {},
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    handleUpdateProject: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { handleUpdateProject } = projectSlice.actions;

export default projectSlice.reducer;
