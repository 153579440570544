import { Flex, GridItem, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

const Empowering = () => {
  return (
    <GridItem
      colStart={{ md: 7 }}
      colSpan={{ md: 4 }}
      rowStart={{ md: 2 }}
      rowEnd={{ md: 11 }}
    >
      <Stack
        h="100%"
        p={5}
        bg="white"
        boxShadow="sm"
        borderWidth="1px"
        borderRadius={5}
        borderColor="blackAlpha.300"
        spacing={5}
      >
        <Flex w="4rem" h="4rem" borderRadius="100%" bg="purple.100" p={4}>
          <Image src="/business.png" />
        </Flex>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Empowering Businesses and Individuals
        </Heading>
        <Text>
          At Modern Village, we believe in the transformative power of
          technology to empower both businesses and individuals. Our solutions
          are designed to streamline processes, enhance efficiency, and provide
          a competitive edge in the digital landscape. We work closely with our
          clients to understand their unique needs and tailor our solutions to
          drive success in their respective domains.
        </Text>
      </Stack>
    </GridItem>
  );
};

export default Empowering;
