import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdDashboard } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { TbWebhook } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

const Admin = () => {
  useEffect(() => {
    if (!localStorage.getItem("token")) window.location.replace("/login");
  }, []);

  return (
    <Flex>
      <Navigation />
      <Box w="calc(100% - 15rem)">
        <Outlet />
      </Box>
    </Flex>
  );
};

const Navigation = () => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={5}
      minH="100vh"
      pos="sticky"
      top={0}
      w="15rem"
      bg="blue.900"
      color="white"
      py={5}
      alignSelf="flex-start"
    >
      <Heading textAlign="center" fontSize="1.5rem">
        Modern Village
      </Heading>
      <VStack my={8} spacing={3} h="100%">
        {/* <NavButton
          props={{
            title: "Analytics",
            icon: <MdDashboard size={22} />,
            link: "/admin/",
          }}
        /> */}
        <NavButton
          props={{
            title: "Portfolio",
            icon: <TbWebhook size={22} />,
            link: "/admin/portfolio",
          }}
        />
        {/* <NavButton
          props={{
            title: "Employee",
            icon: <BsPeopleFill size={22} />,
            link: "/admin/employee",
          }}
        /> */}
      </VStack>
      <Spacer />
      <Button
        // bg={isPath() ? "yellow.400" : "transparent"}
        // color={isPath() ? "blue.900" : "white"}
        bg="transparent"
        color="white"
        leftIcon={<FiLogOut />}
        w="90%"
        _hover={{ bg: "red.300", color: "blue.900" }}
        onClick={() => {
          localStorage.removeItem("token");
          navigate("/login");
        }}
      >
        <Text w="8rem" textAlign="left">
          Log out
        </Text>
      </Button>
    </Stack>
  );
};

const NavButton = ({
  props,
}: {
  props: { icon: JSX.Element; link: string; title: string };
}) => {
  const navigate = useNavigate();
  const { icon, link, title } = props;
  // const [path] = useState(window.location.pathname);

  // const isPath = () => path.includes(link);

  return (
    <Button
      // bg={isPath() ? "yellow.400" : "transparent"}
      // color={isPath() ? "blue.900" : "white"}
      bg="transparent"
      color="white"
      leftIcon={icon}
      w="90%"
      _hover={{ bg: "yellow.400", color: "blue.900" }}
      onClick={() => navigate(link)}
    >
      <Text w="8rem" textAlign="left">
        {title}
      </Text>
    </Button>
  );
};

export default Admin;
