import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { useState } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { BsFacebook, BsLinkedin, BsTelegram, BsTwitter } from "react-icons/bs";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { BiLogoFacebook, BiLogoLinkedin } from "react-icons/bi";
import { PiTelegramLogo } from "react-icons/pi";
import { useSelector } from "react-redux";
import "../../css/hamburger.css";
import useRender from "../../hooks/useRender";
import { wrapCharacters } from "../../utils/wrapCharacters";
import SocialsCard from "../../components/SocialsCard";
import MobileDrawer from "../../components/MobileDrawer";

const Header = () => {
  const [render] = useRender();

  switch (render) {
    case "mobile":
      return <Mobile />;

    case "desktop":
      return <Desktop />;

    default:
      return <Box h="100vh" w="100%"></Box>;
  }
};

const Mobile = () => {
  const navigate = useNavigate();

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from("#welcome span", {
      delay: 1,
      opacity: 0,
      duration: 0.5,
      x: -1,
      stagger: 0.05,
    });

    tl.from("#intro span", {
      opacity: 0,
      duration: 0.5,
      x: -1,
      stagger: 0.05,
    });

    tl.from("#buttons #button", {
      opacity: 0,
      duration: 1,
      y: 50,
      stagger: 0.1,
    });
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      flexDir="column"
      h="100vh"
      w="100%"
      bgImg="/header-background.webp"
      color="white"
      bgRepeat="no-repeat"
      bgSize="cover"
      pos="relative"
      px={5}
      py={10}
      gap={44}
    >
      {isOpen ? <MobileDrawer isOpen={isOpen} onClose={onClose} /> : null}
      <HStack alignItems="center" zIndex={10000}>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Modern Village
        </Heading>
        <Spacer />
        <Button
          variant="unstyled"
          color="white"
          _hover={{}}
          _focus={{}}
          onClick={() => {
            isOpen ? onClose() : onOpen();
          }}
        >
          <svg
            id="hamburger"
            className="ham hamRotate ham1"
            viewBox="0 0 100 100"
            width="40"
            height="40"
            onClick={() => {
              const hamburger = document.getElementById("hamburger");

              !isOpen
                ? hamburger != null && hamburger.classList.add("active")
                : hamburger != null && hamburger.classList.remove("active");
            }}
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path className="line middle" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
        </Button>
      </HStack>
      <Stack spacing={{ base: 32, md: 5 }}>
        <Text
          id="intro"
          pb={{ md: 20 }}
          maxW="45rem"
          textAlign="center"
          fontSize="1.5rem"
          fontWeight={600}
          lineHeight="3rem"
          color="whiteAlpha.800"
        >
          {wrapCharacters("OUR PORTFOLIO")}
        </Text>
        <Grid
          pt={5}
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          w="100%"
          gap={10}
          id="buttons"
        >
          <GridItem id="button">
            <Button
              id="startprojectbtn"
              variant="custom-solid"
              colorScheme="white"
              w="100%"
              borderRadius={0}
              onClick={() => navigate("/")}
            >
              GO BACK HOME
            </Button>
          </GridItem>
          <GridItem id="button">
            <Button
              id="moreaboutusbtn"
              variant="custom-solid"
              colorScheme="white"
              w="100%"
              borderRadius={0}
              onClick={() =>
                document
                  .getElementById("start")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              SCROLL DOWN
            </Button>
          </GridItem>
        </Grid>
      </Stack>
    </Flex>
  );
};

const Desktop = () => {
  const navigate = useNavigate();

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from("#welcome span", {
      delay: 1,
      opacity: 0,
      duration: 0.5,
      x: -1,
      stagger: 0.05,
    });

    tl.from("#intro span", {
      opacity: 0,
      duration: 0.5,
      x: -1,
      stagger: 0.05,
    });

    tl.from("#buttons #button", {
      opacity: 0,
      duration: 0.5,
      y: 50,
      stagger: 0.1,
    });

    tl.from("#socials div", {
      opacity: 0,
      duration: 0.5,
      x: 30,
      stagger: 0.2,
    });
  });

  return (
    <Box
      h={{ base: "80vh", md: "100vh" }}
      w="100%"
      bgImg="/header-background.webp"
      bgRepeat="no-repeat"
      bgSize="cover"
      pos="relative"
    >
      <Flex
        mt={{ md: 10 }}
        p={{ base: 5, md: 28 }}
        w="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="center"
          alignItems="flex-start"
          color="white"
          flexDir="column"
          gap={8}
        >
          <Text
            id="intro"
            pb={{ md: 20 }}
            maxW="45rem"
            textAlign="left"
            fontSize="1.5rem"
            fontWeight={600}
            lineHeight="3rem"
            color="whiteAlpha.800"
          >
            {wrapCharacters("OUR PORTFOLIO")}
          </Text>
          <Grid
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(1, 1fr)"
            w="30rem"
            gap={10}
            id="buttons"
          >
            <GridItem id="button">
              <Button
                onClick={() => navigate("/")}
                id="startprojectbtn"
                variant="custom-solid"
                colorScheme="white"
                size="md"
                borderRadius={0}
              >
                GO BACK HOME
              </Button>
            </GridItem>
            <GridItem id="button">
              <Button
                onClick={() => {
                  document
                    .getElementById("start")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                id="moreaboutusbtn"
                variant="custom-solid"
                colorScheme="white"
                size="md"
                borderRadius={0}
              >
                SCROLL DOWN
              </Button>
            </GridItem>
          </Grid>
        </Flex>
        <Stack color="white" spacing={8} id="socials">
          <SocialsCard title="Facebook" icon={<BiLogoFacebook size={20} />} />
          <SocialsCard title="Twitter" icon={<BsTwitter size={20} />} />
          <SocialsCard title="LinkedIn" icon={<BiLogoLinkedin size={20} />} />
          <SocialsCard title="Telegram" icon={<PiTelegramLogo size={20} />} />
        </Stack>
      </Flex>
    </Box>
  );
};

export default Header;
