import { GridItem, Stack, Flex, Heading, Image, Text } from "@chakra-ui/react";

const OurCommitment = () => {
  return (
    <GridItem colSpan={{ md: 4 }} rowStart={3} rowEnd={{ md: 12 }}>
      <Stack
        h="100%"
        p={5}
        bg="white"
        boxShadow="sm"
        borderWidth="1px"
        borderRadius={5}
        borderColor="blackAlpha.300"
        spacing={5}
      >
        <Flex w="4rem" h="4rem" borderRadius="100%" bg="red.100" p={4}>
          <Image src="/commitment.svg" />
        </Flex>
        <Heading fontSize="1.5rem" fontWeight={500}>
          Our Commitment to International Standards
        </Heading>
        <Text>
          We understand the importance of aligning with global standards in an
          increasingly interconnected world. At Modern Village, we are dedicated
          to developing apps and systems that not only meet but surpass
          international benchmarks. Our commitment to quality assurance,
          security, and user experience ensures that our products are not only
          technologically advanced but also reliable and user-friendly.
        </Text>
      </Stack>
    </GridItem>
  );
};

export default OurCommitment;
