import {
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";
import axios from "axios";
import baseURL from "../../utils/baseURL";

const Portfolio = () => {
  const [render] = useRender();

  const [projects, setProjects] = useState<
    {
      _id: string;
      title: string;
      description: string;
      status: string;
      cover_photo: string;
      stack: string[];
      tags: string[];
    }[]
  >([]);

  const handleFetch = async () => {
    const { data } = await axios.get(`${baseURL}/project`);

    setProjects(data.projects);
  };

  useEffect(() => {
    document.title = "Modern Village | Portfolio";

    handleFetch();
  }, []);

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack>
      {!render ? <Loader /> : null}
      <Header />
      <LocalHeader />
      <Outlet />
      <Grid
        mb={20}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        templateRows="repeat(1, 1fr)"
        id="projects"
        p={{ base: 5, md: 0 }}
        w="100%"
        maxW="75rem"
        mx="auto"
        rowGap={5}
      >
        {projects.map(
          (
            { _id, title, description, cover_photo, stack, tags, status },
            index
          ) => (
            <ProjectCard
              key={index}
              _id={_id}
              title={title}
              description={description}
              cover_photo={cover_photo}
              stack={stack}
              tags={tags}
              status={status}
            />
          )
        )}
      </Grid>
    </Stack>
  );
};

const LocalHeader = () => {
  return (
    <Flex
      id="start"
      h="sm"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      gap={5}
      maxW="md"
      mx="auto"
      w="95%"
    >
      <Heading fontSize="1.8rem" fontWeight="600">
        See Our Projects
      </Heading>
      <Text>Mobile and web applications built to solve problems</Text>
      {/* <InputGroup bg="white">
        <InputLeftElement>
          <BsSearch />
        </InputLeftElement>
        <Input placeholder="Search..." />
      </InputGroup> */}
    </Flex>
  );
};

const ProjectCard = ({
  _id,
  title,
  description,
  status,
  cover_photo,
  stack,
  tags,
}: {
  _id: string;
  title: string;
  description: string;
  status: string;
  cover_photo: string;
  stack: string[];
  tags: string[];
}) => {
  const navigate = useNavigate();

  return (
    <Box
      cursor="pointer"
      onClick={() => navigate(`/portfolio/${_id}`)}
      w={{ base: "100%", md: "sm" }}
      h="sm"
      bg="white"
      boxShadow="md"
      p={4}
    >
      <Image
        loading="lazy"
        src={cover_photo}
        h="85%"
        w="100%"
        objectFit="cover"
        objectPosition="center"
      />
      <HStack h="15%">
        <Text>{title}</Text>
      </HStack>
    </Box>
  );
};

export default Portfolio;
