import "@tremor/react/dist/esm/tremor.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/landingpage/Homepage";
import Admin from "./pages/admin/Admin";
import Analytics from "./pages/admin/Analytics";
import AdminPortfolio from "./pages/admin/portfolio/Portfolio";
import EditPortfolio from "./pages/admin/portfolio/EditPortfolio";
import Employee from "./pages/admin/employee/Employee";
import EmployeeDetails from "./pages/admin/employee/EmployeeDetails";
import Details from "./pages/admin/employee/tabs/Details";
import Bank from "./pages/admin/employee/tabs/Bank";
import Contact from "./pages/admin/employee/tabs/Contact";
import Portfolio from "./pages/portfolio/Portfolio";
import ContactUs from "./pages/contact/ContactUs";
import PortfolioModal from "./pages/portfolio/PortfolioModal";
import Login from "./pages/login/Login";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import AddPortfolio from "./pages/admin/portfolio/AddPortfolio";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/portfolio" element={<Portfolio />}>
        <Route path=":id" element={<PortfolioModal />} />
      </Route>
      <Route path="contact" element={<ContactUs />} />
      <Route path="about" element={<About />} />
      <Route path="services" element={<Services />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="login" element={<Login />} />
      <Route path="/admin" element={<Admin />}>
        <Route path="" element={<Analytics />} />
        <Route path="portfolio" element={<AdminPortfolio />} />
        <Route path="portfolio/add" element={<AddPortfolio />} />
        <Route path="portfolio/:portfolio" element={<EditPortfolio />} />
        <Route path="employee" element={<Employee />}>
          <Route path=":id" element={<EmployeeDetails />}>
            <Route path="" element={<Details />} />
            <Route path="bank" element={<Bank />} />
            <Route path="contact" element={<Contact />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
