import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "../../components/Footer";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";
import useRender from "../../hooks/useRender";
import Loader from "../../components/Loader";
import baseURL from "../../utils/baseURL";
import { FaCheck } from "react-icons/fa";

const ContactUs = () => {
  const [render] = useRender();

  useEffect(() => {
    if (!render) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [render]);

  return (
    <Stack spacing={{ base: 5, md: 20 }} w="100%">
      {!render ? <Loader /> : null}
      <Header />
      <Lines />
      <RequestQuote />
      <Footer theme="dark" />
    </Stack>
  );
};

const Lines = () => {
  return (
    <Grid
      id="start"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
      gap={5}
      w={{ base: "sm", md: "100%" }}
      maxW="75rem"
      mx="auto"
      p={{ base: 5, md: 0 }}
    >
      <VStack spacing={3} bg="gray.100" p={8} borderRadius={5}>
        <Icon as={MdCall} boxSize={35} color="blue.500" />
        <Text fontWeight={600} fontSize="1.5rem">
          Give us a call
        </Text>
        <Text>(+234) 08137779598</Text>
        <Text>(+234) 07038209875</Text>
      </VStack>
      <VStack spacing={3} bg="gray.100" p={8} borderRadius={5}>
        <Icon as={MdEmail} boxSize={35} color="blue.500" />
        <Text fontWeight={600} fontSize="1.5rem">
          Drop us a line
        </Text>
        <Text></Text>
        <Text>hello@modernvillage.ng</Text>
      </VStack>
      <VStack spacing={3} bg="gray.100" p={8} borderRadius={5}>
        <Icon as={MdLocationPin} boxSize={35} color="blue.500" />
        <Text fontWeight={600} fontSize="1.5rem">
          Visit our office
        </Text>
        <Text textAlign="center">56 Old Yaba Road, Adekunle, Yaba, Lagos</Text>
      </VStack>
    </Grid>
  );
};

const RequestQuote = () => {
  const toast = useToast();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSend = async () => {
    setLoading(true);

    const { data } = await axios.post(
      `${baseURL}/mail`,
      {
        name,
        email,
        subject,
        message,
      },
      { validateStatus: () => true }
    );

    setLoading(false);

    if (data.status === true) setSuccess(true);
    else
      toast({
        title: "Request couldn't be completed",
        description:
          "We encountered an error while sending your email. Please try again.",
        isClosable: true,
        status: "warning",
        duration: 3000,
      });
  };

  return success ? (
    <Box w="100%" p={5}>
      <Stack
        p={{ base: 5, md: 8 }}
        w="100%"
        maxW="55rem"
        mx="auto"
        alignItems="center"
        spacing={5}
        borderWidth={1}
        boxShadow={{ md: "md" }}
      >
        <Flex bg="green.500" color="white" borderRadius="100%" p={5}>
          <FaCheck size={20} />
        </Flex>
        <Heading color="green.500" fontSize="1.5rem" fontWeight={500}>
          Email Sent
        </Heading>
        <Text textAlign="center">
          Thank you for your email. We will get back to you shortly.
        </Text>
      </Stack>
    </Box>
  ) : (
    <Center p={{ base: 5, md: 0 }}>
      <Stack
        p={8}
        w={{ base: "100%", md: "100%" }}
        maxW="55rem"
        mx="auto"
        alignItems="center"
        spacing={5}
        borderWidth={1}
        boxShadow="md"
      >
        <Heading fontSize="1rem" fontWeight={500} color="blue.500">
          REQUEST A QUOTE
        </Heading>
        <Heading>How May We Help You?</Heading>
        <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={5}>
          <Input
            onChange={(e) => setName(e.target.value)}
            placeholder="Name*"
          />
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email*"
          />
        </Grid>
        <Input
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject"
        />
        <Textarea
          onChange={(e) => setMessage(e.target.value)}
          h="10rem"
          placeholder="Write a message*"
          resize="none"
        />
        <Button
          onClick={handleSend}
          w="75%"
          size="lg"
          colorScheme="blue"
          isDisabled={!name || !email || !message}
          isLoading={loading}
          loadingText="Processing. Please wait..."
        >
          Send Message
        </Button>
      </Stack>
    </Center>
  );
};

export default ContactUs;
